<script setup>
const route = useRoute();

useHead({
  link: [{ rel: "canonical", href: "https://miraway.vn" + route.path }],
  meta: [
    { name: "og:url", content: "https://miraway.vn" + route.path },
    { name: "og:image", content: "https://miraway.vn/images/social-thumb.jpg" },
    { name: "twitter:card", content: "summary" },
    { name: "twitter:image", content: "https://miraway.vn/images/social-thumb.jpg" },
  ],
});
</script>

<template>
  <Preloader />

  <DelayHydration>
    <PageHeader />
    <div :class="{ 'pt-[88px]': $route.path != '/' }"><NuxtPage /></div>
    <LazyRelatedSolutions v-if="$route.path.includes('/giai-phap')" class="mt-[80px]" />
    <LazyContactAndFillInfoAdvise v-if="$route.path.includes('/giai-phap')" class="px-[120px] my-[80px]" />
    <PageFooter />
  </DelayHydration>
</template>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.15s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>

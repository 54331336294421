<script setup>
const LAYOUT_NORMAL = "Normal";
const LAYOUT_SOLUTION = "Solution";

const route = useRoute();

const layout = ref(LAYOUT_NORMAL);
const isModalVisible = ref(false);
const isHomePage = computed(() => route.path === "/");
const isTextWhite = computed(() => layout.value === LAYOUT_SOLUTION || !isHomePage.value);

const { hasLoadDrawer } = useDrawer(1500, isModalVisible);

const handleScroll = () => {
  if (isHomePage.value) {
    isScrolled.value = window.scrollY > 20;
    layout.value = isScrolled.value ? LAYOUT_NORMAL : LAYOUT_SOLUTION;
  } else {
    isScrolled.value = false;
  }
};

onMounted(() => {
  window?.addEventListener("scroll", handleScroll);

  if (process.env.NODE_ENV !== "development") {
    Array.from(document.querySelectorAll("a[href^='http://localhost']")).forEach((node) => {
      node.href = node.href.replace("http://localhost", window.location.origin);
    });
  }
});

onBeforeUnmount(() => {
  window?.removeEventListener("scroll", handleScroll);
});

const setLayoutByRoute = () => {
  if (route.path === "/" || route.path.includes("giai-phap")) {
    layout.value = LAYOUT_SOLUTION;
  } else {
    layout.value = LAYOUT_NORMAL;
  }
};
setLayoutByRoute();

const isScrolled = ref(false);
const subMenu = ref();

watch(
  () => route.fullPath,
  () => {
    subMenu.value.classList.add("!hidden");

    setTimeout(() => {
      subMenu.value.classList.remove("!hidden");
    }, 100);

    isModalVisible.value = false;
    isScrolled.value = false;

    setLayoutByRoute();
  }
);
</script>

<template>
  <header
    :class="{
      'shadow-lg !bg-white': isScrolled && isHomePage,
      'bg-transparent': isHomePage,
      'bg-fifth': !isHomePage,
    }"
  >
    <div class="2xl:container px-[120px] max-md:flex max-md:justify-between">
      <nuxt-link to="/">
        <nuxt-picture
          preload
          src="/images/logo.png"
          alt="logo"
          width="173"
          :img-attrs="{ alt: 'logo', height: '43.66' }"
          :class="{ 'filter-white': !isScrolled }"
        />
      </nuxt-link>

      <button @click="isModalVisible = true" class="border-none p-0 lg:hidden">
        <nuxt-picture
          src="/images/3-dots-circle.png"
          width="32"
          height="32"
          :class="{ 'filter-white': layout === 'Solution' || !isHomePage }"
          :img-attrs="{ alt: 'icon-close' }"
        />
      </button>

      <div class="nav-menu flex gap-x-4 max-lg:hidden">
        <nuxt-link to="/gioi-thieu" class="px-4 py-3 text-center" :class="{ '!text-white': isTextWhite }"
          >Giới thiệu</nuxt-link
        >
        <!-- <nuxt-link to="/san-pham" class="px-4 py-3 text-center" :class="{ '!text-white': isTextWhite }">Sản phẩm</nuxt-link> -->
        <!-- Giai Phap -->
        <div id="giai-phap" class="nav-menu flex gap-x-4 max-lg:hidden">
          <div
            class="relative px-4 py-3 text-center"
            :class="{ 'router-link-active': $route.path.includes('/giai-phap') }"
          >
            <nuxt-link to="/giai-phap/cetm" :class="{ 'text-white': isTextWhite, 'text-[#777e90]': !isTextWhite }"
              >Giải pháp</nuxt-link
            >

            <div class="absolute top-[100%] left-0 pt-5">
              <div
                ref="submenu"
                class="sub-menu hidden rounded-b-xl bg-white !grid-cols-[repeat(3,auto)] cursor-default w-[650px]"
              >
                <div class="sub-menu-container">
                  <div class="sub-menu-item cursor-default">Kênh Offline to Online</div>
                  <nuxt-link to="/giai-phap/digital-signage" class="sub-menu-item">
                    <nuxt-picture
                      loading="lazy"
                      class="shrink-0"
                      src="/images/icons/ic-solution-ds.svg"
                      :img-attrs="{ alt: 'Digital Signage' }"
                    />
                    <div>Digital Signage</div>
                  </nuxt-link>
                  <nuxt-link to="/giai-phap/self-service-kiosk" class="sub-menu-item">
                    <nuxt-picture
                      loading="lazy"
                      class="shrink-0"
                      src="/images/icons/ic-solution-ssk.svg"
                      :img-attrs="{ alt: 'Self-service Kiosk' }"
                    />
                    <div>Self-service Kiosk</div>
                  </nuxt-link>
                  <nuxt-link to="/giai-phap/he-thong-xep-hang-tu-dong" class="sub-menu-item">
                    <nuxt-picture
                      loading="lazy"
                      class="shrink-0"
                      src="/images/icons/ic-solution-sq.svg"
                      :img-attrs="{ alt: 'Đặt lịch xếp hàng' }"
                    />
                    <div>Đặt lịch xếp hàng</div>
                  </nuxt-link>
                  <nuxt-link to="/giai-phap/vms-camera" class="sub-menu-item">
                    <nuxt-picture
                      loading="lazy"
                      class="shrink-0"
                      src="/images/icons/ic-solution-vms-camera.svg"
                      :img-attrs="{ alt: 'VMS - AI Camera' }"
                    />
                    <div>VMS - AI Camera</div>
                  </nuxt-link>
                  <nuxt-link to="/giai-phap/so-hoa-tai-lieu" class="sub-menu-item">
                    <nuxt-picture
                      loading="lazy"
                      class="shrink-0"
                      src="/images/icons/ic-solution-sq.svg"
                      :img-attrs="{ alt: 'Số hóa tài liệu' }"
                    />
                    <div>Số hóa tài liệu</div>
                  </nuxt-link>
                  <nuxt-link to="/giai-phap/trung-tam-du-lieu" class="sub-menu-item">
                    <nuxt-picture
                      loading="lazy"
                      class="shrink-0"
                      src="/images/icons/ic-solution-cx.svg"
                      :img-attrs="{ alt: 'Trung tâm dữ liệu' }"
                    />
                    <div>Trung tâm dữ liệu</div>
                  </nuxt-link>
                </div>
                <div class="sub-menu-container">
                  <div class="sub-menu-item cursor-default">Kênh Online</div>
                  <nuxt-link to="/giai-phap/cdp" class="sub-menu-item">
                    <nuxt-picture
                      loading="lazy"
                      class="shrink-0"
                      src="/images/icons/ic-solution-cdp.svg"
                      :img-attrs="{ alt: 'CDP - Nền tảng phân tích dữ liệu khách hàng' }"
                    />
                    <div>CDP - Nền tảng phân tích dữ liệu khách hàng</div>
                  </nuxt-link>
                  <nuxt-link to="/giai-phap/quan-ly-tai-san" class="sub-menu-item">
                    <nuxt-picture
                      loading="lazy"
                      class="shrink-0"
                      src="/images/icons/ic-solution-qlts.svg"
                      :img-attrs="{ alt: 'Quản lý tài sản' }"
                    />
                    <div>Quản lý tài sản</div>
                  </nuxt-link>
                  <nuxt-link to="/giai-phap/e-form" class="sub-menu-item">
                    <nuxt-picture
                      loading="lazy"
                      class="shrink-0"
                      src="/images/icons/ic-solution-eform.svg"
                      :img-attrs="{ alt: 'Biểu mẫu điện từ E-Form' }"
                    />
                    <div>Biểu mẫu điện từ E-Form</div>
                  </nuxt-link>
                  <nuxt-link to="/giai-phap/cx" class="sub-menu-item">
                    <nuxt-picture
                      loading="lazy"
                      class="shrink-0"
                      src="/images/icons/ic-solution-cx.svg"
                      :img-attrs="{ alt: 'Trải nghiệm khách hàng đa kênh' }"
                    />
                    <div>Trải nghiệm khách hàng đa kênh</div>
                  </nuxt-link>
                  <nuxt-link to="https://qrx.com.vn/" class="sub-menu-item" target="_blank">
                    <nuxt-picture
                      loading="lazy"
                      class="shrink-0"
                      src="/images/icons/ic-solution-qr.svg"
                      :img-attrs="{ alt: 'QRX - Nền tảng kinh doanh D2C' }"
                    />
                    <div>QRX - Nền tảng kinh doanh D2C</div>
                  </nuxt-link>
                </div>
                <div class="sub-menu-container">
                  <div class="cursor-default">
                    <nuxt-link to="/san-pham"><p class="sub-menu-item">Sản phẩm</p></nuxt-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Tin tuc su kien -->
        <div id="tin-tuc-su-kien" class="nav-menu flex gap-x-4 max-lg:hidden">
          <div class="relative px-4 py-3 text-center">
            <nuxt-link
              to="https://miraway.vn/blog/"
              target="_blank"
              :class="{ 'text-white': isTextWhite, 'text-[#777e90]': !isTextWhite }"
              >Tin tức & Sự kiện</nuxt-link
            >

            <div class="absolute top-[100%] left-0 pt-5">
              <div
                ref="subMenu"
                class="sub-menu hidden rounded-b-xl bg-white !grid-cols-[repeat(2,auto)] cursor-default w-[530px]"
              >
                <div class="sub-menu-container">
                  <nuxt-link
                    to="https://miraway.vn/blog/category/tin-tuc-su-kien/tin-tuc/"
                    class="sub-menu-item"
                    target="_blank"
                    ><div class="font-bold text-black">Tin tức</div></nuxt-link
                  >
                  <nuxt-link
                    to="https://miraway.vn/blog/category/tin-tuc-su-kien/cau-chuyen-khach-hang/"
                    class="sub-menu-item"
                    target="_blank"
                    ><div>Câu chuyện khách hàng</div></nuxt-link
                  >
                  <nuxt-link
                    to="https://miraway.vn/blog/category/tin-tuc-su-kien/truyen-thong-noi-ve-miraway/"
                    class="sub-menu-item"
                    target="_blank"
                    ><div>Truyền thông nói về Miraway</div></nuxt-link
                  >
                  <nuxt-link
                    to="https://miraway.vn/blog/category/tin-tuc-su-kien/su-kien/"
                    class="sub-menu-item"
                    target="_blank"
                    ><div>Sự kiện</div></nuxt-link
                  >
                </div>
                <div class="sub-menu-container">
                  <nuxt-link
                    to="https://miraway.vn/blog/category/tin-tuc-su-kien/blog/"
                    class="sub-menu-item"
                    target="_blank"
                  >
                    <div class="font-bold text-black">Blog</div>
                  </nuxt-link>
                  <nuxt-link
                    to="https://miraway.vn/blog/category/tin-tuc-su-kien/blog/digital-signage/"
                    class="sub-menu-item"
                    target="_blank"
                  >
                    <div>Digital signage</div>
                  </nuxt-link>
                  <nuxt-link
                    to="https://miraway.vn/blog/category/tin-tuc-su-kien/blog/he-thong-xep-hang-tu-dong/"
                    class="sub-menu-item"
                    target="_blank"
                  >
                    <div>Hệ thống xếp hàng tự động</div>
                  </nuxt-link>
                  <nuxt-link
                    to="https://miraway.vn/blog/category/tin-tuc-su-kien/blog/kiosk-tu-phuc-vu/"
                    class="sub-menu-item"
                    target="_blank"
                  >
                    <div>Kiosk tự phục vụ</div>
                  </nuxt-link>
                  <nuxt-link
                    to="https://miraway.vn/blog/category/tin-tuc-su-kien/blog/cdp/"
                    class="sub-menu-item"
                    target="_blank"
                  >
                    <div>Giải pháp CDP</div>
                  </nuxt-link>
                  <nuxt-link
                    to="https://miraway.vn/blog/category/tin-tuc-su-kien/blog/e-form/"
                    class="sub-menu-item"
                    target="_blank"
                  >
                    <div>E form</div>
                  </nuxt-link>
                  <nuxt-link
                    to="https://miraway.vn/blog/category/tin-tuc-su-kien/blog/he-thong-khao-sat-phan-hoi/"
                    class="sub-menu-item"
                    target="_blank"
                  >
                    <div>Hệ thống khảo sát phản hồi</div>
                  </nuxt-link>
                  <nuxt-link
                    to="https://miraway.vn/blog/category/tin-tuc-su-kien/blog/he-thong-loyalty/"
                    class="sub-menu-item"
                    target="_blank"
                  >
                    <div>Hệ thống Loyalty</div>
                  </nuxt-link>
                  <nuxt-link
                    to="https://miraway.vn/blog/category/tin-tuc-su-kien/blog/he-thong-nhan-dien-khuon-mat/"
                    class="sub-menu-item"
                    target="_blank"
                  >
                    <div>Hệ thống nhận diện khuôn mặt</div>
                  </nuxt-link>
                  <nuxt-link
                    to="https://miraway.vn/blog/category/customer-centric/"
                    class="sub-menu-item"
                    target="_blank"
                  >
                    <div>Customer centric</div>
                  </nuxt-link>
                  <nuxt-link
                    to="https://miraway.vn/blog/category/tin-tuc-su-kien/blog/trai-nghiem-khach-hang/"
                    class="sub-menu-item"
                    target="_blank"
                  >
                    <div>Trải nghiệm khách hàng</div>
                  </nuxt-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <nuxt-link to="/tuyen-dung" class="px-4 py-3 text-center" :class="{ '!text-white': isTextWhite }"
          >Cơ hội nghề nghiệp</nuxt-link
        >
        <nuxt-link to="/lien-he" class="px-4 py-3 text-center" :class="{ '!text-white': isTextWhite }"
          >Liên hệ</nuxt-link
        >
      </div>
    </div>

    <LazyDrawer v-if="hasLoadDrawer" v-model:visible="isModalVisible" />
  </header>
</template>

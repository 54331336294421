import { default as chi_45tiet_45spRBdxZPvk7GMeta } from "/home/runner/work/Miraway/Miraway/pages/chi-tiet-sp.vue?macro=true";
import { default as chinh_45sach_45bao_45matGZCtL4R5o2Meta } from "/home/runner/work/Miraway/Miraway/pages/chinh-sach-bao-mat.vue?macro=true";
import { default as dang_45ky_45doi_45tacLjMrIR2tYvMeta } from "/home/runner/work/Miraway/Miraway/pages/dang-ky-doi-tac.vue?macro=true";
import { default as dieu_45khoankEYcDPAdb2Meta } from "/home/runner/work/Miraway/Miraway/pages/dieu-khoan.vue?macro=true";
import { default as cdpzB31DUUpGvMeta } from "/home/runner/work/Miraway/Miraway/pages/giai-phap/cdp.vue?macro=true";
import { default as cetmJjew6jdkA0Meta } from "/home/runner/work/Miraway/Miraway/pages/giai-phap/cetm.vue?macro=true";
import { default as cxup63wGSC7VMeta } from "/home/runner/work/Miraway/Miraway/pages/giai-phap/cx.vue?macro=true";
import { default as digital_45signagetgaZc6ZNLVMeta } from "/home/runner/work/Miraway/Miraway/pages/giai-phap/digital-signage.vue?macro=true";
import { default as e_45formSb7u6wGSe8Meta } from "/home/runner/work/Miraway/Miraway/pages/giai-phap/e-form.vue?macro=true";
import { default as he_45thong_45xep_45hang_45tu_45dong8XBxpX37fvMeta } from "/home/runner/work/Miraway/Miraway/pages/giai-phap/he-thong-xep-hang-tu-dong.vue?macro=true";
import { default as may_45ban_45hang_45tu_45dongReeEj9yKzBMeta } from "/home/runner/work/Miraway/Miraway/pages/giai-phap/may-ban-hang-tu-dong.vue?macro=true";
import { default as phan_45hoi_45khach_45hangkQwRVsUlKZMeta } from "/home/runner/work/Miraway/Miraway/pages/giai-phap/phan-hoi-khach-hang.vue?macro=true";
import { default as quan_45ly_45tai_45sanJLTFwi1JApMeta } from "/home/runner/work/Miraway/Miraway/pages/giai-phap/quan-ly-tai-san.vue?macro=true";
import { default as self_45service_45kioskG530Rsx5d3Meta } from "/home/runner/work/Miraway/Miraway/pages/giai-phap/self-service-kiosk.vue?macro=true";
import { default as so_45hoa_45tai_45lieuQAOvI4rsklMeta } from "/home/runner/work/Miraway/Miraway/pages/giai-phap/so-hoa-tai-lieu.vue?macro=true";
import { default as trung_45tam_45du_45lieu64ts9ZZjo8Meta } from "/home/runner/work/Miraway/Miraway/pages/giai-phap/trung-tam-du-lieu.vue?macro=true";
import { default as vms_45cameraSZaywpWhpeMeta } from "/home/runner/work/Miraway/Miraway/pages/giai-phap/vms-camera.vue?macro=true";
import { default as gioi_45thieutGFGldJ2esMeta } from "/home/runner/work/Miraway/Miraway/pages/gioi-thieu.vue?macro=true";
import { default as index2aczXjEPdRMeta } from "/home/runner/work/Miraway/Miraway/pages/index.vue?macro=true";
import { default as lien_45he0CnxVp6S8fMeta } from "/home/runner/work/Miraway/Miraway/pages/lien-he.vue?macro=true";
import { default as san_45phamIB6MJt4sARMeta } from "/home/runner/work/Miraway/Miraway/pages/san-pham.vue?macro=true";
import { default as tuyen_45dung0nFNQsAbVEMeta } from "/home/runner/work/Miraway/Miraway/pages/tuyen-dung.vue?macro=true";
export default [
  {
    name: "chi-tiet-sp",
    path: "/chi-tiet-sp",
    component: () => import("/home/runner/work/Miraway/Miraway/pages/chi-tiet-sp.vue").then(m => m.default || m)
  },
  {
    name: "chinh-sach-bao-mat",
    path: "/chinh-sach-bao-mat",
    component: () => import("/home/runner/work/Miraway/Miraway/pages/chinh-sach-bao-mat.vue").then(m => m.default || m)
  },
  {
    name: "dang-ky-doi-tac",
    path: "/dang-ky-doi-tac",
    component: () => import("/home/runner/work/Miraway/Miraway/pages/dang-ky-doi-tac.vue").then(m => m.default || m)
  },
  {
    name: "dieu-khoan",
    path: "/dieu-khoan",
    component: () => import("/home/runner/work/Miraway/Miraway/pages/dieu-khoan.vue").then(m => m.default || m)
  },
  {
    name: "giai-phap-cdp",
    path: "/giai-phap/cdp",
    component: () => import("/home/runner/work/Miraway/Miraway/pages/giai-phap/cdp.vue").then(m => m.default || m)
  },
  {
    name: "giai-phap-cetm",
    path: "/giai-phap/cetm",
    component: () => import("/home/runner/work/Miraway/Miraway/pages/giai-phap/cetm.vue").then(m => m.default || m)
  },
  {
    name: "giai-phap-cx",
    path: "/giai-phap/cx",
    component: () => import("/home/runner/work/Miraway/Miraway/pages/giai-phap/cx.vue").then(m => m.default || m)
  },
  {
    name: "giai-phap-digital-signage",
    path: "/giai-phap/digital-signage",
    component: () => import("/home/runner/work/Miraway/Miraway/pages/giai-phap/digital-signage.vue").then(m => m.default || m)
  },
  {
    name: "giai-phap-e-form",
    path: "/giai-phap/e-form",
    component: () => import("/home/runner/work/Miraway/Miraway/pages/giai-phap/e-form.vue").then(m => m.default || m)
  },
  {
    name: "giai-phap-he-thong-xep-hang-tu-dong",
    path: "/giai-phap/he-thong-xep-hang-tu-dong",
    component: () => import("/home/runner/work/Miraway/Miraway/pages/giai-phap/he-thong-xep-hang-tu-dong.vue").then(m => m.default || m)
  },
  {
    name: "giai-phap-may-ban-hang-tu-dong",
    path: "/giai-phap/may-ban-hang-tu-dong",
    component: () => import("/home/runner/work/Miraway/Miraway/pages/giai-phap/may-ban-hang-tu-dong.vue").then(m => m.default || m)
  },
  {
    name: "giai-phap-phan-hoi-khach-hang",
    path: "/giai-phap/phan-hoi-khach-hang",
    component: () => import("/home/runner/work/Miraway/Miraway/pages/giai-phap/phan-hoi-khach-hang.vue").then(m => m.default || m)
  },
  {
    name: "giai-phap-quan-ly-tai-san",
    path: "/giai-phap/quan-ly-tai-san",
    component: () => import("/home/runner/work/Miraway/Miraway/pages/giai-phap/quan-ly-tai-san.vue").then(m => m.default || m)
  },
  {
    name: "giai-phap-self-service-kiosk",
    path: "/giai-phap/self-service-kiosk",
    component: () => import("/home/runner/work/Miraway/Miraway/pages/giai-phap/self-service-kiosk.vue").then(m => m.default || m)
  },
  {
    name: "giai-phap-so-hoa-tai-lieu",
    path: "/giai-phap/so-hoa-tai-lieu",
    component: () => import("/home/runner/work/Miraway/Miraway/pages/giai-phap/so-hoa-tai-lieu.vue").then(m => m.default || m)
  },
  {
    name: "giai-phap-trung-tam-du-lieu",
    path: "/giai-phap/trung-tam-du-lieu",
    component: () => import("/home/runner/work/Miraway/Miraway/pages/giai-phap/trung-tam-du-lieu.vue").then(m => m.default || m)
  },
  {
    name: "giai-phap-vms-camera",
    path: "/giai-phap/vms-camera",
    component: () => import("/home/runner/work/Miraway/Miraway/pages/giai-phap/vms-camera.vue").then(m => m.default || m)
  },
  {
    name: "gioi-thieu",
    path: "/gioi-thieu",
    component: () => import("/home/runner/work/Miraway/Miraway/pages/gioi-thieu.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/Miraway/Miraway/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "lien-he",
    path: "/lien-he",
    component: () => import("/home/runner/work/Miraway/Miraway/pages/lien-he.vue").then(m => m.default || m)
  },
  {
    name: "san-pham",
    path: "/san-pham",
    component: () => import("/home/runner/work/Miraway/Miraway/pages/san-pham.vue").then(m => m.default || m)
  },
  {
    name: "tuyen-dung",
    path: "/tuyen-dung",
    component: () => import("/home/runner/work/Miraway/Miraway/pages/tuyen-dung.vue").then(m => m.default || m)
  }
]
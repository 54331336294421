<script setup>
const url = useRequestURL(); // get current domain url
const domainBlog = url.origin + "/blog"; //https://beta1.miraway.vn/
</script>

<template>
  <footer>
    <div class="2xl:container px-[120px] max-lg:!px-0 lg:py-[40px]">
      <div class="lg:w-1/4 max-lg:w-1/3 max-sm:w-1/2">
        <nuxt-picture
          loading="lazy"
          src="/images/white-logo.png"
          :img-attrs="{ alt: 'logo', width: 237, height: 60, class: 'w-full' }"
        />
      </div>
      <div class="flex max-lg:flex-wrap lg:gap-x-[50px] mt-2">
        <div class="w-5/12 max-lg:w-full">
          <div class="font-bold">CÔNG TY CỔ PHẦN MIRAWAY GIẢI PHÁP CÔNG NGHỆ</div>
          <div class="mt-6">
            <div class="flex gap-x-2">
              <nuxt-picture
                loading="lazy"
                src="/images/icons/location.svg"
                class="shrink-0"
                :img-attrs="{ alt: 'location-hn' }"
              />
              <div>VP Tại Hà Nội: Tầng 4 - Tòa nhà GTC, Số 69, phố Tố Hữu, Vạn Phúc, Hà Đông, Hà Nội</div>
            </div>
            <div class="flex gap-x-2 mt-2">
              <nuxt-picture
                loading="lazy"
                src="/images/icons/location.svg"
                class="shrink-0"
                :img-attrs="{ alt: 'location-hcm' }"
              />
              <div>VP Tại HCM: 211, đường 9A Trung Sơn, Bình Hưng, Bình Chánh, TP Hồ Chí Minh</div>
            </div>
          </div>
          <div class="mt-5 flex flex-col gap-y-2">
            <div class="flex gap-x-2">
              <nuxt-picture
                loading="lazy"
                src="/images/icons/phone.svg"
                class="shrink-0"
                :img-attrs="{ alt: 'tel-phone' }"
              />
              <div>Hotline CSKH: 024 36 436 999</div>
            </div>
            <div class="flex gap-x-2">
              <nuxt-picture
                loading="lazy"
                src="/images/icons/mobile.svg"
                class="shrink-0"
                :img-attrs="{ alt: 'hotline-24/7' }"
              />
              <div>Hotline tư vấn: 0981 350 960</div>
            </div>
            <div class="flex gap-x-2">
              <nuxt-picture
                loading="lazy"
                src="/images/icons/mail.svg"
                class="shrink-0"
                :img-attrs="{ alt: 'contact' }"
              />
              <div>contact@miraway.vn</div>
            </div>
          </div>
        </div>
        <div class="w-2/12 max-lg:w-1/2 max-lg:pt-[40px] flex flex-col gap-y-6">
          <nuxt-link to="/gioi-thieu" class="font-bold"> Giới thiệu Miraway </nuxt-link>
          <nuxt-link to="/tuyen-dung" class="font-bold"> Cơ hội nghề nghiệp </nuxt-link>
          <nuxt-link :to="domainBlog" class="font-bold" target="_blank"> Tin tức & Hoạt động </nuxt-link>
          <nuxt-link to="/lien-he" class="font-bold"> Liên hệ </nuxt-link>
        </div>
        <div class="w-2/12 max-lg:w-1/2 max-lg:pt-[40px] flex flex-col gap-y-6">
          <nuxt-link to="/chinh-sach-bao-mat" class="font-bold">Chính sách bảo mật</nuxt-link>
          <nuxt-link to="/dieu-khoan" class="font-bold">Điều khoản sử dụng</nuxt-link>
        </div>
        <div class="w-2/12 max-lg:w-full flex flex-col gap-y-6">
          <div>Mạng xã hội</div>
          <div class="flex gap-x-3">
            <nuxt-link to="https://www.facebook.com/Technology.Solution.Miraway" target="_blank">
              <nuxt-picture loading="lazy" src="/images/icons/fb.svg" :img-attrs="{ alt: 'facebook' }" />
            </nuxt-link>
            <nuxt-link to="https://www.youtube.com/@miraway3008" target="_blank">
              <nuxt-picture loading="lazy" src="/images/icons/youtube.svg" :img-attrs="{ alt: 'youtube' }" />
            </nuxt-link>
            <nuxt-link to="https://www.linkedin.com/company/miraway" target="_blank">
              <nuxt-picture loading="lazy" src="/images/icons/linkedin.svg" :img-attrs="{ alt: 'linkedin' }" />
            </nuxt-link>
          </div>
        </div>
      </div>
      <!-- <FacebookChat/> -->
      <div class="mt-24 text-extra-small">© Copyright - 2023 | MIRAWAY | All Rights Reserved</div>
    </div>
  </footer>
</template>
